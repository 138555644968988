import { ThemeContext } from 'contexts/theme'
import { useContext } from 'react'
import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import './Header.css'

const Header = () => {
  //const [{ themeName }] = useContext(ThemeContext)
  const { homepage, title } = header
  //const logo = "/assets/logo-" + themeName + ".svg";
  const logo = "/assets/logo-dark.svg";
  return (
    <header className='header center'>
      <h3>
        {homepage ? (
          <a href={homepage} className='link' id='logo'>
            {title}
        {/*<img src={logo} alt='Burn LUNC or send to Oracle or Community Pool' />*/}
          </a>
        ) : (
          title
        )}
      </h3>
      <Navbar />
    </header>
  )
}

export default Header

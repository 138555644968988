import React from "react";
import "./Modal.css";

export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal-layer" id="modal">
        <div className="modal">
          <h2>{this.props.title}</h2>
          <div className="content">{this.props.children}</div>
          {this.props.closeable && (
            <div className="actions">
              <button className="toggle-button" onClick={this.onClose}>
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
import { LCDClient, Wallet } from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
//import { ConnectedWallet } from "@terra-money/wallet-types";

export interface NFTResponse {
  access: object,
  info: object,
  [k: string]: unknown;
}
export type ExecuteMsg = {
  burn: {
    coins: number,
    [k: string]: unknown;
  };
};
export type NFT = {
  access: {
    owner?: string,
    approvals?: object
  },
  info: {
    extension?: {
      image: string,
      name: string,
      description: string,
      current_status: string
    }
  }
};
export type Addr = string;
export type QueryMsg = {
  Burned: {
    [k: string]: unknown;
  };
};
export interface State {
  burned: number;
  owner: Addr;
  [k: string]: unknown;
}
export interface BlazeNFTReadOnlyInterface {
  contractAddress: string;
  getNFT: (owner: Addr) => Promise<NFTResponse>;
}
export class BlazeNFTQueryClient implements BlazeNFTReadOnlyInterface {
  client: LCDClient;
  contractAddress: string;

  constructor(client: LCDClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.getNFT = this.getNFT.bind(this);
  }

  getNFT = async (owner: Addr): Promise<any> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      all_nft_info: {
        token_id: owner
      }
    });
  };
}
export interface BlazeNFTInterface extends BlazeNFTReadOnlyInterface {
  contractAddress: string;
  getNFT: ( owner: string ) => Promise<NFTResponse>;
}

export class BlazeNFTClient extends BlazeNFTQueryClient implements BlazeNFTInterface {
  client: LCDClient;
  wallet: Wallet | ConnectedWallet;
  contractAddress: string;

  constructor(client: LCDClient, wallet: Wallet | ConnectedWallet, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.wallet = wallet;
    this.contractAddress = contractAddress;
  }
}
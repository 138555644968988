import { useWallet, WalletStatus } from '@terra-money/wallet-provider';
import './ConnectWallet/ConnectWallet.css'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

export const ConnectWallet = () => {
  const {
    status,
    network,
    wallets,
    availableConnectTypes,
    availableConnections,
    connect,
    disconnect,
  } = useWallet();

  return (
    <div>
      {/*<section>
        <pre>
          {JSON.stringify(
            {
              status,
              wallets,
            },
            null,
            2,
          )}
        </pre>
          </section>*/}

        {status === WalletStatus.WALLET_NOT_CONNECTED && (

          <ul>
            {availableConnections.map(
              ({ type, name, icon, identifier = '' }) => (
                <li key={type}>
                <button
                  key={'connection-' + type + identifier}
                  onClick={() => connect(type, identifier)}
                >
                  <img
                    src={icon}
                    alt={name}
                    style={{ width: '1em', height: '1em' }}
                  />
                  {name}
                </button>
                </li>
              ),
            )}
          </ul>
        )}
        {status === WalletStatus.WALLET_CONNECTED && (
          <div>
            <span
              className="connected-wallet">{wallets[0].terraAddress}</span>
            <button className="btn btn--icon" onClick={() => disconnect()}><ExitToAppIcon /></button>
          </div>
        )}
    </div>
  );
}

import { LCDClient, Coins, Wallet, MsgExecuteContract, TxInfo, WaitTxBroadcastResult, Coin, Fee, ExtensionOptions, MsgFundCommunityPool, MsgSend } from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
//import { ConnectedWallet } from "@terra-money/wallet-types";

function isConnectedWallet(x: Wallet | ConnectedWallet): x is ConnectedWallet {
  return typeof (x as Wallet).key === "undefined";
};
async function getTaxAmount(amount?: Coins, lcd?: LCDClient) {
  if(!amount || !lcd) {
    return 0;
  }
  
  const taxRate = await(
    await fetch(lcd.config.URL + '/terra/treasury/v1beta1/tax_rate', {
      redirect: 'follow'
    })
  ).json();

  const amountUluna = amount.get('uluna');

  if(!amountUluna) {
    return 0;
  }

  const taxInUluna = amountUluna.amount.toNumber() * taxRate.tax_rate;

  return taxInUluna;
}

async function waitForInclusionInBlock(lcd: LCDClient, txHash: string): Promise<TxInfo | undefined> {
  let res;
  for (let i = 0; i <= 50; i++) {
    try {
      res = await lcd.tx.txInfo(txHash);
    } catch (error) {
      // NOOP
    }
      
    if (res) {
      break;
    }
      
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
      
  return res;
};
export interface BurnedResponse {
  burned: number;
  [k: string]: unknown;
}
export interface TotalBurnedResponse {
  total: number;
  [k: string]: unknown;
}
export type ExecuteMsg = {
  burn: {
    coins: number,
    [k: string]: unknown;
  };
};
export type Addr = string;
export type QueryMsg = {
  Burned: {
    [k: string]: unknown;
  };
};
export interface State {
  burned: number;
  owner: Addr;
  [k: string]: unknown;
}
export interface LuncBlazeReadOnlyInterface {
  contractAddress: string;
  getBurned: (owner: Addr) => Promise<BurnedResponse>;
}
export class LuncBlazeQueryClient implements LuncBlazeReadOnlyInterface {
  client: LCDClient;
  contractAddress: string;

  constructor(client: LCDClient, contractAddress: string) {
    this.client = client;
    this.contractAddress = contractAddress;
    this.getBurned = this.getBurned.bind(this);
  }

  getBurned = async (owner: Addr): Promise<BurnedResponse> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      Burned: { address: owner }
    });
  };
  getTotal = async (): Promise<TotalBurnedResponse> => {
    return this.client.wasm.contractQuery(this.contractAddress, {
      TotalBurned: {}
    });
  }
}
export interface LuncBlazeInterface extends LuncBlazeReadOnlyInterface {
  contractAddress: string;
  burn: (funds?: Coins) => Promise<any>;
  getBurned: ( owner: string ) => Promise<any>;
  getTotal: () => Promise<any>;
}
export class LuncBlazeClient extends LuncBlazeQueryClient implements LuncBlazeInterface {
  client: LCDClient;
  wallet: Wallet | ConnectedWallet;
  contractAddress: string;

  constructor(client: LCDClient, wallet: Wallet | ConnectedWallet, contractAddress: string) {
    super(client, contractAddress);
    this.client = client;
    this.wallet = wallet;
    this.contractAddress = contractAddress;
    this.burn = this.burn.bind(this);
  }

  burn = async (funds?: Coins, cpool?: Coins, orpool?: Coins): Promise<WaitTxBroadcastResult | TxInfo | undefined> => {
    const senderAddress = isConnectedWallet(this.wallet) ? this.wallet.walletAddress : this.wallet.key.accAddress;
    const execMsg = new MsgExecuteContract(senderAddress, this.contractAddress, {
      Burn: {}
    }, funds);

    let tax = await getTaxAmount(funds, this.client);

    let msgs = [];
    if(funds && funds.get('uluna')?.amount.gt(0)) {
      msgs.push(execMsg);
    }
    if(cpool && cpool.get('uluna')?.amount.gt(0)) {
      msgs.push(new MsgFundCommunityPool(senderAddress, cpool));
    }
    if(orpool && orpool.get('uluna')?.amount.gt(0)) {
      tax += await getTaxAmount(orpool, this.client);
      msgs.push(new MsgSend(senderAddress, "terra1jgp27m8fykex4e4jtt0l7ze8q528ux2lh4zh0f", orpool));
    }

    let txdata : ExtensionOptions = {
        memo: senderAddress,
        msgs: msgs,
        isClassic: true,
    };

    const gasPrices = await(
      await fetch('https://terra-classic-fcd.publicnode.com/v1/txs/gas_prices', {})
    ).json();
  
    const gasAdjustment = 2.5;
    const gasPricesCoins = new Coins(gasPrices);

    const account = await this.client.auth.accountInfo(senderAddress);
    const signerDataArray = [{
      address: senderAddress,
      publicKey: account.getPublicKey(),
      sequenceNumber: account.getSequenceNumber()
    }];

    var txFee = await this.client.tx.estimateFee(signerDataArray, { msgs: msgs, gasPrices: gasPricesCoins, gasAdjustment: gasAdjustment, feeDenoms: ['uluna'] });
    
    txdata.fee = new Fee(txFee.gas_limit, txFee.amount.add(new Coin('uluna', tax)));

    if (isConnectedWallet(this.wallet)) {
      const tx = await this.wallet.post(txdata);
      return waitForInclusionInBlock(this.client, tx.result.txhash);
    } else {
      const execTx = await this.wallet.createAndSignTx(txdata);
      return this.client.tx.broadcast(execTx);
    }
  };
}
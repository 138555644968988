import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider';
import App from './App';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'contexts/theme';

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <ThemeProvider>
    <WalletProvider {...chainOptions}>
      <App />
    </WalletProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
});

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://burn.luncdash.com',
  title: 'Burn LUNC or send to Oracle or Community Pool',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: '',
  role: '',
  description:
    '',
  resume: '',
  social: {
    twitter: '',
    youtube: ''
  },
}

export { header, about }

import './App.css'
import { useContext, useEffect, useState, useMemo, SetStateAction } from 'react'
import { ThemeContext } from './contexts/theme'

import {
  useWallet,
  useConnectedWallet,
  useLCDClient,
  WalletStatus,
} from '@terra-money/wallet-provider'
import { LuncBlazeClient } from './contract/clients/LuncBlazeClient'
import { BlazeNFTClient } from './contract/clients/BlazeNFTClient'
import { NFTDisplay } from './components/NFTDisplay'
import { Coin, Coins } from '@terra-money/terra.js'
import Header from 'components/Header/Header'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import Modal from 'components/Modal/Modal'
import About from 'components/About/About'
import ModalDonate from 'components/ModalDonate/ModalDonate'

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  const [burned, setBurned] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showDonateHCC, setShowDonateHCC] = useState(false)
  const [showDonateStrath, setShowDonateStrath] = useState(false)
  const [burnAmount, setBurnAmount] = useState(0)
  const [cpAmount, setCpAmount] = useState(0)
  const [orAmount, setOrAmount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalBurnedAmount, setTotalBurnedAmount] = useState(0)
  const [nftInfo, setNFTInfo] = useState({access:{},info:{}})
  const [errorTitle, setErrorTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorTx, setErrorTx] = useState('')
  const [modalCloseable, setModalCloseable] = useState(true)
  const [orPart, setOrPart] = useState(0)
  const [cpPart, setCpPart] = useState(0)

  const { status, network } = useWallet()
  const connectedWallet = useConnectedWallet()
  const lcd = useLCDClient({isClassic: true})

  let lastTxHash = '';

  const contractClient = useMemo(() => {
    if (!connectedWallet) {
      return;
    }
    return new LuncBlazeClient(lcd, connectedWallet, "terra1y02pee3kf2ayy7queh4w9khanr5dd4ucw73zw2");
  }, [lcd, connectedWallet]);

  const contractNFTClient = useMemo(() => {
    if(!connectedWallet) {
      return;
    }
    return new BlazeNFTClient(lcd, connectedWallet, "terra1ry8fxp0zctfcll38yqgq7cd99ls8h9ycrutd8r");
  }, [lcd, connectedWallet]);

  useEffect(() => {
    setBurnAmount(0);
    setOrAmount(0);
    setCpAmount(0);
    setOrPart(0);
    setCpPart(0);
  }, []);

  useEffect(() => {
    const prefetch = async () => {
      if (contractClient && connectedWallet && contractNFTClient) {
        const { burned } = await contractClient.getBurned(connectedWallet.walletAddress);
        setBurned(burned / 1000000);
        /*const { total } = await contractClient.getTotal();
        console.log('total',total);
        setTotalAmount(total / 1000000)*/
        contractNFTClient.getNFT(connectedWallet.walletAddress)
          .then((nft) => setNFTInfo(nft))
          .catch((error) => {
            console.log('MYERR',error)
            let error_message;
            switch(error.response.status) {
                case 500:
                case 501: {
                  switch(error.response.data.code) {
                    case 12:
                      error_message = 'This site only works on terra classic network. Please switch the network.';
                      break;
                    default:
                      break;
                  }
                  break;
                }
                default: {
                  error_message = 'An unknown error occured. Please make sure you are connected to classic network.';
                  break;
                }
            }
            if(error_message) {
              onShowModal('An error occured', error_message);
            }
          });
        
      } else {
        setBurned(0)
        setNFTInfo({access:{},info:{}})
      }
    }
    prefetch()
  }, [contractClient])

  const onClickBurn = async () => {
    if (contractClient && connectedWallet && contractNFTClient && burnAmount + cpAmount + orAmount > 0) {
      onShowModal('Sending your LUNC', 'Please wait while we try to send your LUNC.', true);
      contractClient.burn(new Coins([new Coin("uluna", burnAmount * 1000000)]), new Coins([new Coin("uluna", cpAmount * 1000000)]), new Coins([new Coin("uluna", orAmount * 1000000)]))
        .then((txinfo) => {
          setTotalAmount(0);
          setBurnAmount(0);
          setCpAmount(0);
          setOrAmount(0);
          if(txinfo) {
            lastTxHash = txinfo.txhash;
          } else {
            lastTxHash = '';
          }
        })
        .catch((error) => {
          console.log('MYERR',error)
            let error_message;
            switch(error.response.status) {
                case 400: {
                  switch(error.response.data.code) {
                    case 3: {
                      error_message = 'Insufficient funds in your wallet. Please make sure you have some USTC for gas fees and enough LUNC to pay transaction burn tax.';
                      break;
                    }
                    default: {
                      error_message = 'An unknown error occured. Please check your wallet balance.';
                      break;
                    }
                  }
                  break;
                }
                case 500:
                case 501: {
                  error_message = 'This site only works on terra classic network. Please switch the network.';
                  break;
                }
                default: {
                  error_message = 'An unknown error occured. Please make sure you are connected to classic network.';
                  break;
                }
            }
            onShowModal('An error occured', error_message);
        }).finally(async () => {
          const nft = contractNFTClient.getNFT(connectedWallet.walletAddress)
          .then((nft) => {
            setNFTInfo(nft);
            onHideModal()
            const show_title = 'LUNC burned!';
            const show_message = 'Hey! Good job. You sent some LUNC and helped the movement.';
            onShowModal(show_title, show_message, false, lastTxHash);
          })
          .catch(() => {
            onHideModal()
          })
          .finally(() => {
            
          });
          const { burned } = await contractClient.getBurned(connectedWallet.walletAddress);
          setBurned(burned / 1000000)
          /*const { total } = await contractClient.getTotal();
          console.log('total',total);
          setTotalAmount(total / 1000000)*/
        });
    }
  }

  const onShowModal = async (title: string, text: string, noclose?: boolean, txhash?: string) => {
    setErrorMessage(text);
    setErrorTitle(title);
    setErrorTx(txhash || '');
    setShowModal(true);
    setModalCloseable(noclose ? false : true);
  }
  const onHideModal = async () => {
    setShowModal(false);
  }
  const onHideDonate = async () => {
    setShowDonateStrath(false);
    setShowDonateHCC(false);
  }
  const onShowDonateStrath = async() => {
    setShowDonateStrath(true);
  }
  const onShowDonateHCC = async() => {
    setShowDonateHCC(true);
  }
  const recalcShares = () => {
    let burn = 100;
    let oracle = orPart;
    let cpool = cpPart;
    if(oracle + cpool > 100) {
      oracle = 100 - cpool;
    }
    if(cpool > 100) {
      cpool = 100;
    }
    burn = 100 - oracle - cpool;

    setBurnAmount(Math.floor(totalAmount * burn / 100));
    setOrAmount(Math.floor(totalAmount * oracle / 100));
    setCpAmount(Math.floor(totalAmount * cpool / 100));
  }

  useEffect(() => {
    recalcShares();
  }, [totalAmount, burnAmount, cpAmount, orAmount, orPart, cpPart]);
  const handleChangeAmount = (e: any) => {
    setTotalAmount(+e.target.value);
  }
  const handleOrChange = (e: any) => {
    setOrPart(+e.target.value);
  }
  const handleCpChange = (e: any) => {
    setCpPart(+e.target.value);
  }

  return (
    <>
    <div id='network-banner'>{network.name} ({network.chainID})</div>
    {network.chainID !== 'columbus-5' && (
      <div id='network-banner-warn'>This site only works on LUNC (classic chain). Please change your network.</div>
    )}
    <div id='top' className={`${themeName} app`}>
      <Header />
      <div className='container'>
      <main>
        {status === WalletStatus.WALLET_CONNECTED && (
          <>
          <h4 className='text-center'>Burn LUNC or send to Oracle or Community Pool</h4>
          <div className='burn-input-container'>
            <input
              id="burn-amount"
              type="number"
              onChange={handleChangeAmount}
              value={totalAmount}
            />
            <button className="btn" onClick={onClickBurn} type="button">
              {' '}
              Send LUNC{' '}
            </button>
            <div className='burn-input-percentage'>
            <select id="cp-amount" onChange={handleCpChange}>
              <option value="0">0% to community pool</option>
              <option value="10">10% to community pool</option>
              <option value="20">20% to community pool</option>
              <option value="30">30% to community pool</option>
              <option value="40">40% to community pool</option>
              <option value="50">50% to community pool</option>
              <option value="60">60% to community pool</option>
              <option value="70">70% to community pool</option>
              <option value="80">80% to community pool</option>
              <option value="90">90% to community pool</option>
              <option value="100">100% to community pool</option>
            </select>
            <select id="or-amount" onChange={handleOrChange}>
              <option value="0">0% to oracle pool</option>
              <option value="10">10% to oracle pool</option>
              <option value="20">20% to oracle pool</option>
              <option value="30">30% to oracle pool</option>
              <option value="40">40% to oracle pool</option>
              <option value="50">50% to oracle pool</option>
              <option value="60">60% to oracle pool</option>
              <option value="70">70% to oracle pool</option>
              <option value="80">80% to oracle pool</option>
              <option value="90">90% to oracle pool</option>
              <option value="100">100% to oracle pool</option>
            </select>
          </div>
          <div className='send-numbers'>
              <span>Burn: {burnAmount.toLocaleString()} LUNC</span>
              <span>Community Pool: {cpAmount.toLocaleString()} LUNC</span>
              <span>Oracle Pool: {orAmount.toLocaleString()} LUNC</span>
            </div>
          </div>
         
                {/*
<div className='nft-display'>
           <NFTDisplay access={nftInfo.access} info={nftInfo.info} />
           <p>
          <strong>{burned.toLocaleString()} LUNC burned by you so far</strong>
          </p>

        </div>*/}
        </>
      )}
      {status === WalletStatus.WALLET_NOT_CONNECTED && (
        <div className='not-connected'>
        <p>Your wallet is not connected. Please connect it now to <strong>burn LUNC or send it to Community/Oracle pool</strong>!</p>
        </div>
      )}
      </main>
        {/*{totalBurnedAmount > 0 && (
          <>
          <hr />
          <h3>{totalBurnedAmount.toLocaleString()} LUNC burned through LUNCblaze</h3>
        </>
      )}
      <hr />
        <About />*/}
      </div>
      <ScrollToTop />
      <Modal onClose={onHideModal} title={errorTitle} show={showModal} closeable={modalCloseable}>
      {errorMessage}
      {errorTx && (
        <p className='mt'>View your transaction on finder: <a href={"https://finder.terra.money/classic/tx/" + errorTx} target="_blank" title={errorTx}>click here</a></p>
      )}
      </Modal>

      {/*
      <ModalDonate onClose={onHideDonate} show={showDonateStrath} title="Donations">
      	<p>
					If you like this page and find it helpful, please consider donating a small amount of $LUNC.
				</p>
				<p>Wallet address: <code><a href="https://finder.terra.money/classic/address/terra1wwldp9k8mvkjfrzzmlfde6frymtyt693t7gfsx" target="_blank">terra1wwldp9k8mvkjfrzzmlfde6frymtyt693t7gfsx</a></code></p>
				<p>
					Donations will be distributed / used on a monthly basis as follows:
				</p>
				<ul>
					<li>
						<strong>50%</strong> will be used to cover server and maintenance cost for node and website
					</li>
					<li>
						<strong>50%</strong> will be donated to awesome <a href="https://metagloria.io" target="_blank">MetaGloria</a> project team
					</li>
				</ul>
				<p>
					Thank you for considering a donation. May the LUNC be with you ;-)
				</p>
				<p className='text-right'>
					<em>StrathCole</em>
				</p>
      </ModalDonate>
      
      <a href="#" className='donate-button' onClick={onShowDonateStrath} style={{right:'2rem',bottom:'3.5rem'}}>Donate (StrathCole)</a>
      */}
    </div>
    </>
  )
}

export default App
